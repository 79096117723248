import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import StaffList from '../../../organisms/management/staff/StaffList'
import DepartmentList from '../../../organisms/management/departments/DepartmentList'
import EmploymentStatusList from '../../../organisms/management/employment_statuses/EmploymentStatusList'
import ButtonPrimary from '../../../atoms/share/button/ButtonPrimary';


export const Index = () => {
  return (
    <IndexArea>
      <StaffArea>
        <TitleArea>
          <Title value='従業員一覧' />
        </TitleArea>
        <StaffList />
      </StaffArea>
      <TableArea>
        <DepartmentArea>
          <TitleArea>
            <Title value='グループ一覧' />
            <ButtonPrimary text='新規登録' path='/management/departments/new' />
          </TitleArea>
          <DepartmentList />
        </DepartmentArea>
        <EmploymentStatusArea>
          <TitleArea>
            <Title value='種別一覧' />
            <ButtonPrimary text='新規登録' path='/management/employment_statuses/new' />
          </TitleArea>
          <EmploymentStatusList />
        </EmploymentStatusArea>
      </TableArea>
    </IndexArea>
  )
}

const IndexArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`
const StaffArea = styled.div`
  padding: 1rem;
  border: 1px solid #000000;
  border-radius: 1rem;
`
const TableArea = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
`
const DepartmentArea = styled.div`
  grid-column: span 1 / span 1;
  padding: 1rem;
  border: 1px solid #000000;
  border-radius: 1rem;
`
const EmploymentStatusArea = styled.div`
  grid-column: span 1 / span 1;
  padding: 1rem;
  border: 1px solid #000000;
  border-radius: 1rem;
`
const TitleArea = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
`

export default Index
