import React from 'react'
import styled from 'styled-components'

export const SignupButton = () => {
  return (
    <Signup href='/companies/sign_up'>
      新規登録
    </Signup>
  )
}

const Signup = styled.a`
  display: flex;
  color: #000000;
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid #000000;
  cursor: pointer;
  font-size: 1rem;
  height: 50px;
  padding: 10px 16px;
  align-items: center;
  width: 100%;
  min-width: 2rem;
  vertical-align: middle;
  border-radius: 1rem;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  margin: 0px 20px;
  justify-content: space-around;
  letter-spacing: 0.1rem;
  &:hover {
    color: #ffffff;
    background-color: rgba(105, 105, 105, 0.7);
  }
`

export default SignupButton
