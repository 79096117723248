import React from 'react';
import styled from 'styled-components'

export interface Props {
  companyName: string
  userName: string
}

export const Header: React.FC<Props> = ({ companyName, userName }) => {
  return (
    <HeaderArea>
      <Title>
        従業員アカウント
      </Title>
      <Name>
        <Company>
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 20">
            <path d="M5,3v18h6v-3.5h2V21h6V3H5 M7,5h2v2H7V5 M11,5h2v2h-2V5 M15,5h2v2h-2V5 M7,9h2v2H7V9 M11,9h2v2h-2V9 M15,9h2v2h-2V9 M7,13h2 v2H7V13 M11,13h2v2h-2V13 M15,13h2v2h-2V13 M7,17h2v2H7V17 M15,17h2v2h-2V17z" fill="#14274e" />
          </svg>
          <CompanyName>{companyName}</CompanyName>
        </Company>
        <User>
            <Icon>
              <svg xmlns="http://www.w3.org/2000/svg" width="23.665" height="25.485" viewBox="0 0 23.665 25.485">
                <g id="person" transform="translate(-3.374 -2.25)">
                  <path id="パス_172" data-name="パス 172" d="M20.964,4.1A5.858,5.858,0,0,0,16.6,2.25a5.87,5.87,0,0,0-4.369,1.842,6.022,6.022,0,0,0-1.529,4.6c.252,3.476,2.9,6.3,5.9,6.3s5.641-2.828,5.9-6.3A6,6,0,0,0,20.963,4.1Z" transform="translate(-1.397)" fill="#14274e" />
                  <path id="パス_173" data-name="パス 173" d="M25.219,31.172H5.2a1.764,1.764,0,0,1-1.377-.633,2.01,2.01,0,0,1-.409-1.656,9.742,9.742,0,0,1,4.334-6.471,13.572,13.572,0,0,1,7.463-2.162,13.572,13.572,0,0,1,7.463,2.162A9.736,9.736,0,0,1,27,28.882a2.011,2.011,0,0,1-.409,1.656A1.764,1.764,0,0,1,25.219,31.172Z" transform="translate(0 -3.437)" fill="#14274e" />
                </g>
              </svg>
            </Icon>
          <UserName>{userName}</UserName>
        </User>
      </Name>
    </HeaderArea>
  )
}

const HeaderArea = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 100px;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
`
const Title = styled.div`
  color: #000000;
  font-weight: bold;
  font-size: 1.5rem;
  margin: auto 0;
`
const Name = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
const Company = styled.div`
  display: flex;
  align-items: right;
`
const CompanyName = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
`
const User = styled.div`
  display: flex;
  align-items: right;
`
const Icon = styled.div`
  height: 1.5rem;
  margin: auto 7px;
`
const UserName = styled.span`
  display: flex;
  align-items: center;
  padding-top: 4px;
`

export default Header
