import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import HolidayRequestList from '../../../organisms/management/requests/HolidayRequestList'
import OverWorkRequestList from '../../../organisms/management/requests/OverWorkRequestList'
import HolidayWorkRequestList from '../../../organisms/management/requests/HolidayWorkRequestList'

export const Index = () => {
  return (
    <IndexArea>
      <RequestArea>
        <TitleArea>
          <Title value='休暇申請一覧' />
        </TitleArea>
        <HolidayRequestList />
      </RequestArea>
      <RequestArea>
        <TitleArea>
          <Title value='残業申請一覧' />
        </TitleArea>
        <OverWorkRequestList />
      </RequestArea>
      <RequestArea>
        <TitleArea>
          <Title value='休日出勤申請一覧' />
        </TitleArea>
        <HolidayWorkRequestList />
      </RequestArea>
    </IndexArea>
  )
}

const IndexArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`
const RequestArea = styled.div`
  padding: 2rem;
  border: 1px solid #000000;
  border-radius: 1rem;
`
const TitleArea = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
`

export default Index
