import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Title from '../../atoms/share/Title'
import TextForm from '../../atoms/TextForm'
import SubmitButton from '../../atoms/SubmitButton'
import Info from '../../atoms/share/Info'
import Error from '../../atoms/share/Error'
import { fetchEmployeeInfo, updateCell } from '../../../api/employee'
import { EmployeeInfo } from '../../../types/employee'

export const Settings = () => {
  const [employee, setEmployee] = useState<EmployeeInfo>({
    id: null, name: '', cell: '', birthday: '', email: '', department_id: null, department_name: '', employment_status_id: null, employment_status_name: ''
  })
  const [cell, setCell] = useState(null)
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    fetchEmployeeInfo()
      .then((res) => {
        setEmployee(res.data)
        setCell(res.data.cell)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      })
  }, []);

  const handleSubmit = () => {
    setInfo('')
    setError('')
    setLoading(true)
    updateCell(cell)
      .then(() => {
        setInfo('変更しました。')
      })
      .catch(() => {
        setError('登録内容に不備があります。')
        setCell(null)
      })
    setLoading(false)
  }

  const infoMessage = () => {
    if (info !== "") {
      return <Info>{info}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <SettingsArea>
      <Title value='従業員設定' />
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <SettingsForm>
        <Item>
          <Label>名前</Label>
          <Value>{employee.name}</Value>
        </Item>
        <Item>
          <Label>所属グループ</Label>
          <Value>{employee.department_name}</Value>
        </Item>
        <Item>
          <Label>従業員種別</Label>
          <Value>{employee.employment_status_name}</Value>
        </Item>
        <Item>
          <Label>電話番号</Label>
          <CellForm>
            <TextForm type="tel" placeholder="09012345678" value={cell} onChange={(e) => setCell(e.target.value)} />
          </CellForm>
        </Item>
        <Item>
          <Label>メールアドレス</Label>
          <Value>{employee.email}</Value>
        </Item>
      </SettingsForm>
      <SubmitButton
        text="変更する"
        onClick={handleSubmit}
        disabled={!cell || loading}
      />
    </SettingsArea>
  )
}

const SettingsArea = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  border: 1px solid #000000;
  border-radius: 1rem;
  font-size: 16px;
  padding: 3rem 0;
`
const SettingsForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`
const Notice = styled.div`
  text-align: center;
`
const Item = styled.div`
  display: flex;
  line-height: 43px;
  list-style: none;
`
const Label = styled.label`
  color: #000000;
  text-align: right;
  width: 10rem;
  margin: auto 3rem auto 0;
`
const CellForm = styled.div`
  width: 20rem;
`
const Value = styled.div`
  width: 20rem;
  font-weight: bold;
`

export default Settings
