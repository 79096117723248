import React, { useState } from 'react'
import styled from 'styled-components'
import TextForm from '../../atoms/TextForm'
import SubmitButton from '../../atoms/SubmitButton'
import Error from '../../atoms/share/Error'
import LinkText from '../../atoms/share/LinkText'
import { loginCompany } from '../../../api/company'

export const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, serError] = useState("")

  const handleSubmit = () => {
    setLoading(true)
    loginCompany(email, password)
      .then((res) => {
        document.location.href = '/management'
      })
      .catch((res) => {
        serError(res.response.data.message)
        setLoading(false)
      })
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <BodyArea>
      <LoginArea>
        <TitleArea>
          <Title>管理者ログインページ</Title>
        </TitleArea>
        <Warning>{errorMessage()}</Warning>
        <Form>
          <Item>
            <Label>メールアドレス</Label>
            <TextForm type="email" placeholder="mail@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Item>
          <Item>
            <Label>パスワード</Label>
            <TextForm type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </Item>
          <ButtonArea>
            <SubmitButton
              text="ログイン"
              onClick={handleSubmit}
              disabled={!email || !password || loading}
            />
            <LinkArea>
              <LinkText path={`/users/sign_in`} text='従業員ログインはこちら' />
            </LinkArea>
          </ButtonArea>
        </Form>
      </LoginArea>
    </BodyArea>
  )
}

const BodyArea = styled.div`
  width: 100%;
  padding: 100px 0;
  min-height: 64vh;
`
const LoginArea = styled.div`
  width: 70%;
  margin: 1rem auto;
  padding: 4rem;
  border: 1px solid #808080;
  border-radius: 1rem;
`
const TitleArea = styled.div`
  text-align: center;
`
const Title = styled.h2`
  color: #14274E;
  font-size: 24px;
  margin-border: 50px;
`
const Warning = styled.div`
  text-align: center;
`
const Form = styled.form`
  margin: 76px 100px 0;
  text-align: center;
`
const Item = styled.div`
  display: flex;
  line-height: 43px;
  list-style: none;
  margin-bottom: 24px;
`
const Label = styled.label`
  width: 30%;
  color: #707071;
  text-align: center;
`
const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 75px 0 0 0;
`
const LinkArea = styled.div`
  margin-top: 30px;
`

export default Login
