import React from 'react'
import styled from 'styled-components'
import Header from '../organisms/home/Header'
import Top from '../organisms/home/Top'
import Summary from '../organisms/home/Summary'
import Feature from '../organisms/home/Feature'
import Plan from '../organisms/home/Plan'
import Footer from '../organisms/home/Footer'


export const Home = () => {
  return (
    <HomeArea>
      <Header />
      <Top />
      <Summary />
      <Feature />
      <Plan />
      <Footer />
    </HomeArea>
  )
}

const HomeArea = styled.div`
  font-family: sans-serif, 'Noto Sans JP';
`

export default Home
