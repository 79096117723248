import React from 'react'
import styled from 'styled-components'

const Plan = () => {
  return (
    <PlanArea>
      <Title>料金プラン</Title>
      <Description>
        ※下記プランは税抜価格です<br />
        ※当社サービスは自動更新となっています
      </Description>
      <PlanTable>
        <tr>
          <Head></Head>
          <OneMonthPriceHead>1ヶ月プラン</OneMonthPriceHead>
          <SixMonthPriceHead>6ヶ月プラン</SixMonthPriceHead>
          <YearPriceHead>年間プラン</YearPriceHead>
        </tr>
        <tr>
          <TopBoby>価格</TopBoby>
          <OneMonthPriceBody>30万円</OneMonthPriceBody>
          <SixMonthPriceBody>150万円</SixMonthPriceBody>
          <YearPriceBody>240万円</YearPriceBody>
        </tr>
        <tr>
          <TopBoby>作成可能アカウント数</TopBoby>
          <Body>無制限</Body>
          <Body>無制限</Body>
          <YearBody>無制限</YearBody>
        </tr>
        <tr>
          <TopBoby>機能制限</TopBoby>
          <Body>なし</Body>
          <Body>なし</Body>
          <YearBody>なし</YearBody>
        </tr>
        <tr>
          <TopBoby>オプション</TopBoby>
          <Body>別途追加可</Body>
          <Body>別途追加可</Body>
          <YearBody>別途追加可</YearBody>
        </tr>
      </PlanTable>
      <Options>
        <OptionTitle>オプション</OptionTitle>
        <Description>
          ※下記は税抜価格です
        </Description>
        <OptionTable>
          <thead>
            <tr>
              <th>オプション名</th>
              <th>価格</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <OptionTableTd>導入サポート</OptionTableTd>
              <OptionTableTd><span>45</span>万円 ~</OptionTableTd>
            </tr>
            <tr>
              <OptionTableTd>IT・DXコンサルティング</OptionTableTd>
              <OptionTableTd><span>50</span>万円 ~</OptionTableTd>
            </tr>
            <tr>
              <OptionTableTd>マニュアル作成・社内研修</OptionTableTd>
              <OptionTableTd><span>20</span>万円 ~</OptionTableTd>
            </tr>
          </tbody>
        </OptionTable>
      </Options>
    </PlanArea>
  )
}

const PlanArea = styled.div`
  background: #a9a9a9;
  padding: 50px 150px;
  margin: 100px 0;
`
const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #14274E;
  text-align: center;
  margin: 1rem 0;
`
const PlanTable = styled.table`
  background: #ffffff;
  margin: 0 auto;
  width: 80%;
  border-collapse: separate;
  border-spacing: 1em 3em;
  text-align: center;
`
const Head = styled.th`
  color: #fff;
  padding: 20px 30px;
`
const OneMonthPriceHead = styled(Head)`
  color: #707071;
  background: #ffffff;
  font-size: 1.5rem;
  width: fit-content;
  border: 2px solid #707071;
  border-radius: 1rem;
`
const SixMonthPriceHead = styled(Head)`
  color: #000000;
  background: #ffffff;
  font-size: 1.5rem;
  width: fit-content;
  border: 2px solid #ffa500;
  border-radius: 1rem;
`
const YearPriceHead = styled(Head)`
  background: #ff69b4;
  font-size: 1.5rem;
  font-weight: bold;
  width: fit-content;
  border-radius: 1rem;
`
const Body = styled.td`
  padding: 15px;
`
const TopBoby = styled(Body)`
  color: #707071;
  font-size: 18px;
  text-align: left;
`
const OneMonthPriceBody = styled(Body)`
  color: #707071;
  font-size: 20px;
  font-weight: bold;
`
const SixMonthPriceBody = styled(Body)`
  font-size: 20px;
  font-weight: bold;
`
const YearPriceBody = styled(Body)`
  font-size: 30px;
  font-weight: bold;
`
const YearBody = styled(Body)`
  font-size: 20px;
  font-weight: bold;
`
const Description = styled.div`
  color: #000000;
  font-size: 1rem;
  margin: 0 auto 1rem;
  text-align: center;
`
const Options = styled.div`
  margin-top: 80px;
  background-color: transparent;
`
const OptionTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #14274E;
  text-align: center;
  margin: 0 0 1rem;
`
const OptionTable = styled.table`
  background: #ffffff;
  margin: 0 auto;
  width: 40%;
  border-collapse: separate;
  border-spacing: 1em;
  text-align: center;
`
const OptionTableTd = styled.td`
  font-size: 1.2rem;

  > span {
    font-size: 1.5rem;
    font-weight: bold;
  }
`
export default Plan
