import React from 'react'
import styled from 'styled-components'
import summary1 from 'images/home/summary/1.jpg'
import summary2 from 'images/home/summary/2.jpg'
import summary3 from 'images/home/summary/3.jpg'

export const Summary = () => {
  return (
    <SummaryArea>
      <List>
        <Item>
          <Subheading>勤怠情報DX</Subheading>
          <IconArea>
            <Img src={summary1} />
          </IconArea>
          <TextArea>
            管理者アカウントと社員アカウントが分かれており、<br />
            各社員はそれぞれのアカウントからシンプルな打刻入力で、出退勤を登録できます。<br />
            アナログ管理時代は毎月末に集計作業を要していましたが、システム上で自動集計されるため、
            大幅に業務コスト削減ができます。
          </TextArea>
        </Item>
        <Item>
          <Subheading>シンプルな各種勤怠申請</Subheading>
          <IconArea>
            <Img src={summary2} />
          </IconArea>
          <TextArea>
            各社員はそれぞれのアカウントから休暇、残業、休日出勤申請が可能です。<br />
            申請すると管理者は各種申請一覧からワンクリックで承認・却下対応ができます。<br />
            申請書類の手続き及び管理がシステム上で一元化され、大幅に業務コスト削減ができます。
          </TextArea>
        </Item>
        <Item>
          <Subheading>登録情報はCSV形式でエクスポート可能</Subheading>
          <IconArea>
            <Img src={summary3} />
          </IconArea>
          <TextArea>
            <Name>JOBEVOL</Name>は使いやすさ、情報のシンプルさを追求しています。<br />
            より詳細に管理するフローがある場合に対して、システムに登録された情報を
            CSV形式で出力することが可能です。
          </TextArea>
        </Item>
      </List>
    </SummaryArea>
  )
}

const SummaryArea = styled.div`
  background: #a9a9a9;
  padding-bottom: 3rem;
`
const List = styled.div`
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 1rem;
`
const Item = styled.div`
  width: 30%;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
  box-shadow: 0 0 1rem #ffffff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Img = styled.img`
  margin: auto;
  width: auto;
  height: 100%;
`
const IconArea = styled.div`
  height: 250px;
  margin-bottom: 1rem;
`
const TextArea = styled.div`
  width: 80%;
`
const Subheading = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
`
const Name = styled.span`
  font-family: Permanent Marker Pro;
`

export default Summary
