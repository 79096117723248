import React from 'react';
import styled from 'styled-components'
import Title from '../../atoms/share/Title'
import ScheduleTable from '../../organisms/employees/ScheduleTable'

export const Attendance = () => {
  return (
    <AttendanceArea>
      <TitleArea>
        <Title value='出勤表' />
      </TitleArea>
      <ScheduleTable />
    </AttendanceArea>
  )
}

const AttendanceArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 1px solid #000000;
  border-radius: 1rem;
  padding: 2rem;
`
const TitleArea = styled.div`
  width: 100%;
  text-align: center;
`

export default Attendance
