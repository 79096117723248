import React, { useState } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import Title from '../../../atoms/share/Title'
import SubmitButton from '../../../atoms/SubmitButton'
import TextAreaForm from '../../../atoms/share/TextAreaForm'
import Info from '../../../atoms/share/Info'
import Error from '../../../atoms/share/Error'
import { years, months, days } from '../../../../modules/date'
import { createOverWorkRequest } from '../../../../api/over_work_request'

export const OverWork = () => {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');
  const [error, setError] = useState('');

  const initialFormState = () => {
    setSelectedYear(null)
    setSelectedMonth(null)
    setSelectedDay(null)
    setReason('')
  }

  const handleSubmit = () => {
    setInfo('')
    setError('')
    setLoading(true)
    createOverWorkRequest(selectedYear.value, selectedMonth.value, selectedDay.value, reason)
      .then(() => {
        setInfo('申請が完了しました。')
        initialFormState()
      })
      .catch(() => {
        setError('申請内容に不備があります。')
      })
    setLoading(false)
  }

  const infoMessage = () => {
    if (info !== "") {
      return <Info>{info}</Info>
    }
  }

  const errorMessage = () => {
    if (error !== "") {
      return <Error>{error}</Error>
    }
  }

  return (
    <OverWorkArea>
      <Title value='新規残業申請' />
      <Notice>{infoMessage()}{errorMessage()}</Notice>
      <OverWorkForm>
        <Item>
          <Label>残業出勤日</Label>
          <SelectBox><Select value={selectedYear} onChange={setSelectedYear} options={years} />年</SelectBox>
          <SelectBox><Select value={selectedMonth} onChange={setSelectedMonth} options={months} />月</SelectBox>
          <SelectBox><Select value={selectedDay} onChange={setSelectedDay} options={days} />日</SelectBox>
        </Item>
        <Item>
          <Label>残業理由</Label>
          <TextAreaBox>
            <TextAreaForm value={reason} onChange={(e) => setReason(e.target.value)} />
          </TextAreaBox>
        </Item>
      </OverWorkForm>
      <SubmitButton
        text="申請する"
        onClick={handleSubmit}
        disabled={!selectedYear || !selectedMonth || !selectedDay || loading}
      />
    </OverWorkArea>
  )
}

const OverWorkArea = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  border: 1px solid #000000;
  border-radius: 1rem;
  font-size: 16px;
  padding: 3rem 0;
`
const OverWorkForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
const Notice = styled.div`
  text-align: center;
`
const Item = styled.div`
  display: flex;
  list-style: none;
`
const Label = styled.label`
  color: #000000;
  text-align: right;
  width: 5rem;
  margin: auto 3rem auto 0;
`
const SelectBox = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0 5px;
`
const TextAreaBox = styled.div`
  width: 420px;
`

export default OverWork
