import React, { useState, useEffect } from 'react';
import ButtonRed from '../../../atoms/share/button/ButtonRed'
import ButtonThirdly from '../../../atoms/share/button/ButtonThirdly';
import styled from 'styled-components'
import TableHead from '../../../atoms/share/TableHead'
import { fetchDepartments, deleteDepartment } from '../../../../api/department'
import TableBodyLarge from '../../../atoms/share/table/TableBodyLarge';
import TableButtonArea from '../../../atoms/share/table/TableButtonArea';

export const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    fetchDepartments()
      .then((res) => {
        setDepartments(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
  }, []);

  const heads = ['グループ名', '']

  const handleDelete = (id: number) => {
    deleteDepartment(id)
      .then((res) => {
        setDepartments(
          departments.filter(item =>
            item.id !== res.data.id
          )
        )
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
  }

  return (
    <DepartmentListArea>
      <Table>
        <TableHead items={heads} />
        <Tbody>
          {departments.map((department, index) => (
            <Row key={index}>
              <TableBodyLarge>{department.name}</TableBodyLarge>
              <TableBodyLarge>
                <TableButtonArea>
                  <ButtonThirdly path={`/management/departments/${department.id}/edit`} text='編集' />
                  <ButtonRed text='削除' onClick={() => handleDelete(department.id)} />
                </TableButtonArea>
              </TableBodyLarge>
            </Row>
          ))}
        </Tbody>
      </Table>
    </DepartmentListArea>
  )
}

const DepartmentListArea = styled.div`
`
const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
`
const Tbody = styled.tbody`
  display: contents;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 300px;
`
const Row = styled.tr``

export default DepartmentList
