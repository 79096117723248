import React from 'react'
import styled from 'styled-components'
import staff from 'images/home/feature/icon_users.png'
import download from 'images/home/feature/icon_download.png'
import apply from 'images/home/feature/icon_files.png'

export const Feature = () => {
  return (
    <FeatureArea>
      <Title>機能一覧</Title>
      <List>
        <Item>
          <Img src={staff} />
          <Text>勤怠管理</Text>
        </Item>
        <Item>
          <Img src={apply} />
          <Text>申請管理</Text>
        </Item>
        <Item>
          <Img src={download} />
          <Text>CSVダウンロード</Text>
        </Item>
      </List>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="11.833" height="46.876" viewBox="0 0 11.833 46.876">
        <g id="グループ_4" data-name="グループ 4" transform="translate(-934 -1308.955)">
          <path id="パス_78" data-name="パス 78" d="M21.114,38.969a2.451,2.451,0,0,1-4.529,1.3L10,33.682a2.451,2.451,0,1,1,3.468-3.465l2.745,2.746v-18.5a2.451,2.451,0,1,1,4.9,0V38.967Z" transform="translate(924.719 1296.945)" fill="#9ba4b4" />
          <path id="パス_77" data-name="パス 77" d="M19.476,8.441A2.451,2.451,0,0,1,24,7.141l6.587,6.586a2.451,2.451,0,0,1-3.468,3.465l-2.745-2.746v18.5a2.451,2.451,0,1,1-4.9,0V8.441Z" transform="translate(914.524 1320.432)" fill="#14274e" />
        </g>
      </svg> */}
    </FeatureArea>
  )
}

const FeatureArea = styled.div`
  margin-bottom: 50px;
  text-align: center;
`
const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin: 100px 0 33px 0;
`
const List = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: auto;
  padding: 4rem;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 4rem;
  box-shadow: 0 0 1rem 0 #ffffff, 0 0 1rem 0 #ffffff inset;
`
const Item = styled.div`
`
const Img = styled.img`
  width: 130px;
  height: auto;
`
const Text = styled.p`
  font-size: 16px;
`

export default Feature
