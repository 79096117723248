import React from 'react';
import styled from 'styled-components';

export interface ButtonRedProps {
  onClick: any;
  text: string;
  disabled?: boolean;
}

export const ButtonRed: React.FC<ButtonRedProps> = ({ onClick, text, disabled = false }) => {
  return <BaseButton type='submit' onClick={onClick} disabled={disabled} >{text}</BaseButton>;
};

const BaseButton = styled.button`
  background:#dc2626;
  border: 1px solid #d6d3d0;
  border-radius: 6px;
  box-sizing: border-box;
  color: #ffffff;
  padding: 5px 15px;
  text-decoration: none;
  width: 5rem;
  white-space: nowrap;
  cursor: pointer;
  :disabled {
    background: #9BA4B4;
    pointer-events: none;
  }
  &:hover {
    color: #000000;
    background-color:transparent;
  }
`;

export default ButtonRed
