import React from 'react'
import styled from 'styled-components'
// import { PrimaryButton } from 'smarthr-ui'
// <PrimaryButton>Hello World</PrimaryButton>

export const Footer = () => {
  return (
    <FooterArea>
      <Top>
        <Title>JOBEVOL</Title>
      </Top>
      <MiddleContent>
        運営会社
        <Middle href='https://fastplus.jp/' target={'_blank'} rel='no-referrer'>
          株式会社ファストプラス
        </Middle>
      </MiddleContent>
      <Bottom>
        プライバシー | 利用規約
      </Bottom>
    </FooterArea>
  )
}

const FooterArea = styled.footer`
  height: 4rem;
  background-color:  rgba(0, 0, 0, 0.7);
  color: #ffffff;
  font-size: 20px;
  padding: 20px 40px;
  text-align: center;
`
const Top = styled.div`
`
const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: auto;
  font-family: Permanent Marker Pro;
`
const MiddleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin: 20px 0 50px 0;
`
const Middle = styled.a`
  display: block;
  font-size: 18px;
  margin-left: 10px;
  color: #ffffff;
`
const Bottom = styled.div`
  display: none;
  font-size: 18px;
`

export default Footer
